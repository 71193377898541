import React from 'react';
import Logo from '../assets/images/logo.png';

const Loading = () => (
    <div style={{width: '100%', height: 'Calc(100vh - 120px)', position: 'relative',
        textAlign: 'center', textTransform: 'uppercase',
        backgroundColor: '#DDDDDD', fontFamily: 'Lato sans-serif', fontSize: '36px', fontWeight: 'bold'}}>
        <div style={{margin: '0', position: 'absolute', top: 'Calc(50vh - 150px)', left: 'Calc(50vw - 50px)',
            transform: 'translateY(Calc(150px - 50vh), Calc(50px - 50vw)'}}>
            <img src={Logo} alt={"Logo"} style={{width: '100px', display: "block", marginLeft: "auto", marginRight: "auto"}}/>
            <span>Loading</span>
        </div>
    </div>
);

export default Loading;
