class LangUtil {
    static isValidLanguageParam(lang) {
        return ['th', 'en'].indexOf(lang) >= 0;
    }

    static getLanguageFromParam(lang) {
        if (lang === "th")
            return "THA";
        else if (lang === "en")
            return "ENG";
        else
            return null;
    }

    static getUrlBaseNameFromLanguage(language) {
        if (language === "ENG")
            return "en";
        else if (language === "THA")
            return "th";
        else
            return null;
    }
}

export default LangUtil;