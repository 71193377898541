import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import LangUtil from './LangUtil';
import logo from '../assets/images/logo.png';

import MenuBarContent from '../assets/content/MenuBar.json';

import './MenuBar.css';

class MenuBar extends Component {
    constructor(props) {
        super(props);
        const language = props.selectedLanguage || "ENG";
        this.state = {
            selectedLanguage: language,
            menuExpanded: false
        };
    }

    render() {
        const textContent = MenuBarContent[this.state.selectedLanguage];
        const langBaseName = LangUtil.getUrlBaseNameFromLanguage(this.state.selectedLanguage);
        const selectedLanguageImage = this.getSelectedLanguageImage();
        const thaiLanguageImg = MenuBar.getThaiLanguageImage();
        const engLanguageImg = MenuBar.getEnglishLanguageImage();
        const navLinkTextClass = (this.state.selectedLanguage === "THA") ? "nav-link-text-th" : "nav-link-text";
        const navDropdownClass = (this.state.selectedLanguage === "THA") ? "nav-dropdown-th" : "nav-dropdown";
        return (
            <Navbar bg={"light"} expand={"md"} onToggle={this.setMenuExpanded.bind(this)}
                    expanded={this.state.menuExpanded} itemScope itemType={"http://schema.org/SiteNavigationElement"}>
                <LinkContainer to={"/" + langBaseName}>
                    <Navbar.Brand>
                        <img src={logo} className={"navbar-logo"} alt={"iTara Resort"}/>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls={"basic-navbar-nav"}>
                </Navbar.Toggle>
                <Navbar.Collapse id={"basic-navbar-nav"}>
                    <Nav onSelect={this.closeMenu.bind(this)} className={"justify-content-end"} style={{width: "100%"}}>
                        <LinkContainer exact to={"/" + langBaseName} active={false}>
                            <Nav.Link className={"nav-link"}>
                                <span className={navLinkTextClass}>{textContent.home}</span>
                                <span className={"nav-link-line"}/>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/" + langBaseName + "/accommodation"} active={false}>
                            <Nav.Link className={"nav-link"}>
                                <span className={navLinkTextClass}>{textContent.accommodation}</span>
                                <span className={"nav-link-line"}/>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/" + langBaseName + "/promotion"} active={false}>
                            <Nav.Link className={"nav-link"}>
                                <span className={navLinkTextClass}>{textContent.promotion}</span>
                                <span className={"nav-link-line"}/>
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/" + langBaseName + "/meeting"} active={false}>
                            <Nav.Link className={"nav-link"}>
                                <span className={navLinkTextClass}>{textContent.meeting}</span>
                                <span className={"nav-link-line"}/>
                            </Nav.Link>
                        </LinkContainer>
                        <NavDropdown id={"facilities-dropdown"} title={textContent.facilities}
                                     className={navDropdownClass}>
                            <LinkContainer to={"/" + langBaseName + "/restaurant"} active={false}>
                                <NavDropdown.Item className={"nav-link"}>
                                    <span className={navLinkTextClass}>{textContent.restaurant}</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={"/" + langBaseName + "/spa"} active={false}>
                                <NavDropdown.Item className={"nav-link"}>
                                    <span className={navLinkTextClass}>{textContent.spa}</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={"/" + langBaseName + "/other-facilities"} active={false}>
                                <NavDropdown.Item className={"nav-link"}>
                                    <span className={navLinkTextClass}>{textContent.others}</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown id={"activities-dropdown"} title={textContent.activities} className={navDropdownClass}>
                            <LinkContainer to={"/" + langBaseName + "/in-house-activities"} active={false}>
                                <NavDropdown.Item className={"nav-link"}>
                                    <span className={navLinkTextClass}>{textContent.inHouseActivities}</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={"/" + langBaseName + "/nearby-activities"} active={false}>
                                <NavDropdown.Item className={"nav-link"}>
                                    <span className={navLinkTextClass}>{textContent.nearbyActivities}</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <LinkContainer to={"/" + langBaseName + "/contact-us"} active={false}>
                            <Nav.Link className={"nav-link"}>
                                <span className={navLinkTextClass}>{textContent.contactUs}</span>
                                <span className={"nav-link-line"}/>
                            </Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav>
                        <NavDropdown id={"language-select"} alignRight
                                     title={selectedLanguageImage}
                                     className={navDropdownClass}>
                            <NavDropdown.Item className={"nav-link nav-link-lang"}
                                              onClick={this.handleThaiLanguageSelect.bind(this)}>
                                <span className={"lang-name"}>{textContent.language.thai}</span>
                                {thaiLanguageImg}
                            </NavDropdown.Item>
                            <NavDropdown.Item className={"nav-link nav-link-lang"}
                                              onClick={this.handleEnglishLanguageSelect.bind(this)}>
                                <span className={"lang-name"}>{textContent.language.english}</span>
                                {engLanguageImg}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

    handleThaiLanguageSelect() {
        this.setState({
            selectedLanguage: "THA"
        }, () => {
            console.log(this.props.location);
            this.navigateToPageOfLanguage("th");
        });
    }

    handleEnglishLanguageSelect() {
        this.setState({
            selectedLanguage: "ENG"
        }, () => {
            this.navigateToPageOfLanguage("en");
        })
    }

    navigateToPageOfLanguage(langCode) {
        const pathComponents = this.props.location.pathname.split("/");
        const navPathComponents = pathComponents.slice(2);
        const langComponent = ["", langCode];
        const newPathComponents = langComponent.concat(navPathComponents);
        const newPath = newPathComponents.join("/");
        this.props.history.push(newPath);
        // window.location.reload();
        window.scrollTo(0,0);
    }

    getSelectedLanguageImage() {
        if (this.state.selectedLanguage === "THA")
            return MenuBar.getThaiLanguageImage();
        else if (this.state.selectedLanguage === "ENG")
            return MenuBar.getEnglishLanguageImage();
        else
            return <img alt={"Unknown"}/>
    }

    static getThaiLanguageImage() {
        return <img src={"/th.gif"} style={{width: "auto", height: "15px"}} alt={"Thai"}/>;
    }

    static getEnglishLanguageImage() {
        return <img src={"/en.gif"} style={{width: "auto", height: "15px"}} alt={"English"}/>;
    }

    setMenuExpanded(expanded) {
        this.setState({ menuExpanded: expanded })
    }

    closeMenu() {
        this.setState({ menuExpanded: false });
    }
}

export default withRouter(MenuBar);