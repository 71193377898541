import React, { Component } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { FaPhone } from 'react-icons/fa';
import en from 'date-fns/locale/en-GB';
import th from 'date-fns/locale/th';

import BookingBarContent from '../assets/content/BookingBar.json';

import 'react-datepicker/dist/react-datepicker.min.css';
import './BookingBar.css';
import './DatePicker.css';

class BookingBar extends Component {
    constructor(props) {
        super(props);
        const language = props.selectedLanguage || "ENG";
        this.state = {
            language: language,
            textContent: props.textContent || BookingBarContent[language],
            selectedLanguage: language,
            checkInDate: props.checkInDate || null,
            checkOutDate: props.checkOutDate || null,
            checkInHighlightDates: props.checkInHighlightDates || [],
            checkOutHighlightDates: props.checkOutHighlightDates || [],
            maxStayDays: props.maxStayDays || 30,
            toggleButtonVisible: props.toggleButtonVisible || true,
            formVisible: props.formVisible || false,
            isSmallScreen: false
        }
    }

    componentDidMount() {
        this.updateScreenSize();
        window.addEventListener("resize", this.updateScreenSize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateScreenSize.bind(this));
    }

    updateScreenSize() {
        const threshold = 922;
        const isSmallScreen = (window.innerWidth <= threshold);
        this.setState({
            isSmallScreen: isSmallScreen,
            toggleButtonVisible: isSmallScreen,
            formVisible: !isSmallScreen
        });
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        let changed = false;
        if (props.selectedLanguage !== state.selectedLanguage) {
            newState.selectedLanguage = props.selectedLanguage;
            newState.textContent = BookingBarContent[props.selectedLanguage];
            changed = true;
        }
        return (changed ? newState : null);
    }

    render() {
        const langClass = (this.state.selectedLanguage === "THA" ? "booking-bar-th" : "booking-bar-en");
        const locale = (this.state.selectedLanguage === "THA" ? th : en);

        const datePickerModifiers = {
            flip: { behavior: ["bottom"] },
            preventOverflow: { enabled: true },
            hide: { enabled: false }
        };

        const minCheckInDate = BookingBar.getMinCheckInDate();
        const minCheckOutDate = this.getMinCheckOutDate();
        const phoneBookingText = (<span><FaPhone/> <span style={{color: "#085394"}}>084-874-7474</span></span>);
        // const phoneBookingText = (this.state.isSmallScreen ?
        //         (<span><FaPhone/> <span style={{color: "#085394"}}>084-874-7474</span></span>) :
        //         (<span>{this.state.textContent.phoneReserveMessage}&nbsp;
        //             <span style={{color: "#085394"}}>084-874-7474</span>
        //         </span>)
        // );
        const toggleButtonStyle = {
            display: this.state.toggleButtonVisible ? "block" : "none"
        };
        const formStyle = {
            display: this.state.formVisible ? "block" : "none"
        };
        return (
            <div>
                <Button className={"toggle-button " + langClass} type={"button"} style={toggleButtonStyle}
                        onClick={this.toggleFormVisible.bind(this)}>
                    {this.state.textContent.reservationToggleText}
                </Button>
                <Form action={"https://www.myxcaliber.com/frontend/booking/i-Tara-Resort-Spa"} method={"post"}
                      onSubmit={this.handleSubmit.bind(this)} style={formStyle}
                      className={"booking-bar " + langClass} autoComplete={"off"}>
                    <Container fluid={true}>
                        <Row>
                            <Col xs={12} sm={12} md={9} lg={8}>
                                <Row>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <Row>
                                    <Col>
                                        <Form.Label className={"date-label"} htmlFor={"checkInDate"}>{this.state.textContent.checkInDate}</Form.Label>
                                        <DatePicker className={"date-input-field booking-date"}
                                                    id={"checkInDate"}
                                                    selected={this.state.checkInDate}
                                                    onChange={this.handleCheckInDateChanged.bind(this)}
                                                    name={"criteria.htmlCheckIn"}
                                                    dateFormat={"dd MMM yyyy"}
                                                    minDate={minCheckInDate}
                                                    highlightDates={this.state.checkInHighlightDates}
                                                    placeholderText={this.state.textContent.checkInDate}
                                                    popperPlacement={"bottom"}
                                                    popperModifiers={datePickerModifiers}
                                                    locale={locale}
                                                    onFocus={BookingBar.handleDateFocus}
                                                    onChangeRaw={BookingBar.handleDateChangeRaw}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label className={"date-label"} htmlFor={"checkOutDate"}>{this.state.textContent.checkOutDate}</Form.Label>
                                        <DatePicker className={"date-input-field booking-date"}
                                                    id={"checkOutDate"}
                                                    selected={this.state.checkOutDate}
                                                    disabled={!BookingBar.isValidDate(this.state.checkInDate)}
                                                    onChange={this.handleCheckOutDateChanged.bind(this)}
                                                    name={"criteria.htmlCheckOut"}
                                                    dateFormat={"dd MMM yyyy"}
                                                    minDate={minCheckOutDate}
                                                    highlightDates={this.state.checkOutHighlightDates}
                                                    placeholderText={this.state.textContent.checkOutDate}
                                                    popperPlacement={"bottom"}
                                                    popperModifiers={datePickerModifiers}
                                                    locale={locale}
                                                    onFocus={BookingBar.handleDateFocus}
                                                    onChangeRaw={BookingBar.handleDateChangeRaw}
                                        />
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <Button type={"submit"} formTarget={"_blank"}
                                            className={"submit-booking"}>
                                        {this.state.textContent.bookingText}
                                    </Button>
                                </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={4}>
                                <div className={"booking-phone-wrapper"}>
                                    <Form.Label id={"phone-button-label"} htmlFor={"phone-button"}>{this.state.textContent.phoneReserveMessage}</Form.Label>
                                    <a href={"tel:+66888747474"} id={"phone-button"} className={"action-button button-link"}>
                                        {phoneBookingText}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        );
    }

    toggleFormVisible() {
        this.setState({
            formVisible: !this.state.formVisible
        });
    }

    static getMinCheckInDate() {
        return new Date();
    }

    getMinCheckOutDate() {
        let date;
        if (this.state.checkInDate === "") {
            date = new Date();
        } else {
            date = new Date(this.state.checkInDate);
        }
        date.setDate(date.getDate() + 1);
        return date;
    }

    handleCheckInDateChanged(date) {
        if (!BookingBar.isValidDate(date))
            return;
        let checkOutDate = new Date(this.state.checkOutDate);
        if (date >= this.state.checkOutDate)
            checkOutDate = BookingBar.addDays(date,1);
        const checkInHighlightDates = BookingBar.getCheckInPickerHighlightDates(date, checkOutDate);
        const checkOutHighlightDates = BookingBar.getCheckOutPickerHighlightDates(date, checkOutDate);
        this.setState({
            checkInDate: date,
            checkOutDate: checkOutDate,
            checkInHighlightDates: checkInHighlightDates,
            checkOutHighlightDates: checkOutHighlightDates
        });
    }

    handleCheckOutDateChanged(date) {
        if (!BookingBar.isValidDate(date))
            return;
        const checkInHighlightDates = BookingBar.getCheckInPickerHighlightDates(this.state.checkInDate, date);
        const checkOutHighlightDates = BookingBar.getCheckOutPickerHighlightDates(this.state.checkInDate, date);
        this.setState({
            checkOutDate: date,
            checkInHighlightDates: checkInHighlightDates,
            checkOutHighlightDates: checkOutHighlightDates
        });
    }

    static handleDateFocus(e) {
        e.target.readOnly = true;
    }

    static handleDateChangeRaw(e) {
        e.preventDefault();
    }

    static getCheckInPickerHighlightDates(checkInDate, checkOutDate) {
        const durationDates = [];
        const today = new Date();
        let currentDate = checkInDate;
        while (currentDate <= checkOutDate) {
            durationDates.push(new Date(currentDate));
            currentDate = BookingBar.addDays(currentDate, 1);
        }
        return [
            {"calendar-today": [today]},
            {"calendar-duration": durationDates},
            {"calendar-selected-date": [checkInDate]},
            {"calendar-selected-fixed-date": [checkOutDate]}
        ];
    }

    static getCheckOutPickerHighlightDates(checkInDate, checkOutDate) {
        const durationDates = [];
        const today = new Date();
        let currentDate = checkInDate;
        while (currentDate <= checkOutDate) {
            durationDates.push(new Date(currentDate));
            currentDate = BookingBar.addDays(currentDate, 1);
        }
        return [
            {"calendar-today": [today]},
            {"calendar-duration": durationDates},
            {"calendar-selected-date": [checkOutDate]},
            {"calendar-selected-fixed-date": [checkInDate]}
        ];
    }

    handleSubmit(e) {
        if (!this.validateBookingForm()) {
            e.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    validateBookingForm() {
        const checkInDate = this.state.checkInDate;
        const checkOutDate = this.state.checkOutDate;
        if (!checkInDate) { window.alert("Check-in date is not selected."); return false; }
        if (!_.isDate(checkInDate)) { window.alert("The given check-in date is not a date."); return false; }
        if (!checkOutDate) { window.alert("Check-out date is not selected."); return false; }
        if (!_.isDate(checkOutDate)) { window.alert("The given check-out date is not a date."); return false; }
        return true;
    }

    static isValidDate(date) {
        if (Object.prototype.toString.call(date) === '[object Date]') {
            return !(isNaN(date.getTime()));
        } else
            return false;
    }

    static addDays(date, inc) {
        const newDate = new Date(date.getTime());
        newDate.setDate(date.getDate() + inc);
        return newDate;
    }
}

export default BookingBar;