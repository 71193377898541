import React, { Component } from 'react';
import { FaFacebookSquare, FaInstagram, FaLine } from 'react-icons/fa';
import SocialBarContent from '../assets/content/SocialBar.json';

class SocialBar extends Component {
    constructor(props) {
        super(props);
        const language = props.selectedLanguage || "ENG";
        this.state = {
            textContent: props.textContent || SocialBarContent[language],
            selectedLanguage: language
        };
    }

    static getDerivedStateFromProps(props, state) {
        const updatedState = {};
        let updated = false;
        if (props.selectedLanguage !== state.selectedLanguage) {
            updatedState.selectedLanguage = props.selectedLanguage;
            updatedState.textContent = SocialBarContent[props.selectedLanguage];
            updated = true;
        }
        return (updated ? updatedState : null);
    }

    render() {
        const langClass = (this.state.selectedLanguage === "THA" ? "social-bar-th" : "social-bar-en");
        return (
            <div className={"social-bar " + langClass}>
                <div className={"display-large-screen"}>
                    {this.state.textContent.followUs}
                </div>
                <div>
                    <a className={"icon-link"} id={"facebook"} title={"Facebook Page"}
                       href={"https://www.facebook.com/iTaraResortSpa"} target={"_blank"} rel={"noopener noreferrer"}>
                        <FaFacebookSquare size={'24'}/>
                    </a>
                    <a className={"icon-link"} id={"instagram"} title={"Instagram"}
                       href={"https://www.instagram.com/iTaraResortSpa"} target={"_blank"} rel={"noopener noreferrer"}>
                        <FaInstagram size={'24'}/>
                    </a>
                    <a className={"icon-link"} id={"line"} title={"Line Account"}
                       href={"https://line.me/ti/p/~nj_bam"} target={"_blank"} rel={"noopener noreferrer"}>
                        <FaLine size={'24'}/>
                    </a>
                </div>
            </div>
        );
    }
}

export default SocialBar;