import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderBar from "./HeaderBar";
import FooterBar from "./FooterBar";
import LangUtil from "./LangUtil";

class Layout extends Component {
    constructor(props) {
        super(props);
        const language = Layout.getLanguageFromLocation(props);
        this.state = {
            language: props.language || language
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let updated = false;
        const language = Layout.getLanguageFromLocation(props);
        if (language !== state.language) {
            newState.language = language;
            updated = true;
        }
        return updated ? newState : null;
    }

    render() {
        const langMainSectionClass = (this.state.language === "THA" ? "main-section-th" : "main-section-en");
        return (
            <div className="App">
                <div className={"top-section"}>
                    <HeaderBar selectedLanguage={this.state.language}/>
                </div>
                <div className={"main-section " + langMainSectionClass}>
                    {this.props.children}
                </div>
                <div className={"bottom-section"}>
                    <FooterBar/>
                </div>
            </div>
        );
    }

    static getLanguageFromLocation(props) {
        const path = props.location.pathname;
        const pathParts = path.split("/");
        return (pathParts.length > 1) ? LangUtil.getLanguageFromParam(pathParts[1]) : undefined;
    }
}

export default withRouter(Layout);