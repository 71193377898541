import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class FooterBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textContent: props.textContent || {}
        }
    }

    render() {
        const today = new Date();
        return (
            <Container className={"footer-bar"} fluid={true} itemScope itemType={"http://schema.org/WPFooter"}>
                <Row>
                    <Col lg={6}>
                        <div>
                            <small>© Copyright {today.getFullYear()} i Tara Resort & Spa. All Rights Reserved.</small>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <small>
                                This site is protected by reCAPTCHA and the Google&nbsp;
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default FooterBar;