import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MenuBar from './MenuBar';
import BookingBar from './BookingBar';
import SocialBar from "./SocialBar";
import "./SocialBar.css";

class HeaderBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: props.selectedLanguage || "ENG"
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let updated = false;
        if (props.selectedLanguage && props.selectedLanguage !== state.selectedLanguage) {
            newState.selectedLanguage = props.selectedLanguage;
            updated = true;
        }
        return updated ? newState : null;
    }

    render() {
        return (
            <div>
                <MenuBar selectedLanguage={this.state.selectedLanguage}/>
                <BookingBar selectedLanguage={this.state.selectedLanguage}/>
                <SocialBar selectedLanguage={this.state.selectedLanguage}/>
            </div>
        );
    }
}

export default withRouter(HeaderBar);