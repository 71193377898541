import React, { Component, Suspense, lazy } from 'react';
import './App.css';
import './content.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Layout from "./components/Layout";

import Loading from './components/Loading';
const Home = lazy(() => import("./app/Home"));
const Accommodation = lazy(() => import("./app/Accommodation"));
const SeaviewDeluxe = lazy(() => import("./app/SeaviewDeluxe"));
const OceanviewVilla = lazy(() => import("./app/OceanviewVilla"));
const BeachfrontVilla = lazy(() => import("./app/BeachfrontVilla"));
const Promotion = lazy(() => import("./app/Promotion"));
const Meeting = lazy(() => import("./app/Meeting"));
const Restaurant = lazy(() => import("./app/Restaurant"));
const Spa = lazy(() => import("./app/Spa"));
const OtherFacilities = lazy(() => import("./app/OtherFacilities"));
const InHouseActivities = lazy(() => import("./app/InHouseActivities"));
const NearbyActivities = lazy(() => import("./app/NearbyActivities"));
const ContactUs = lazy(() => import("./app/ContactUs"));
const NotFound = lazy(() => import("./app/NotFound"));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: props.language || "ENG"
        };
    }

    render() {
        return (
            <Router>
                <Layout>
                    <Suspense fallback={<Loading/>}>
                        <Switch>
                            <Redirect exact from="/" to={"/en"}/>
                            <Route path={"/:lang"} exact component={Home}/>
                            <Route path={"/:lang/accommodation"} exact component={Accommodation}/>
                            <Route path={"/:lang/accommodation/seaview-deluxe"} component={SeaviewDeluxe}/>
                            <Route path={"/:lang/accommodation/oceanview-villa"} component={OceanviewVilla}/>
                            <Route path={"/:lang/accommodation/beachfront-villa"} component={BeachfrontVilla}/>
                            <Route path={"/:lang/promotion"} component={Promotion}/>
                            <Route path={"/:lang/meeting"} component={Meeting}/>
                            <Route path={"/:lang/restaurant"} component={Restaurant}/>
                            <Route path={"/:lang/spa"} component={Spa}/>
                            <Route path={"/:lang/other-facilities"} component={OtherFacilities}/>
                            <Route path={"/:lang/in-house-activities"} component={InHouseActivities}/>
                            <Route path={"/:lang/nearby-activities"} component={NearbyActivities}/>
                            <Route path={"/:lang/contact-us"} component={ContactUs}/>
                            <Route path={"/:lang/not-found"} component={NotFound} status={404}/>
                            <Redirect to={"/en/not-found"}/>}/>
                        </Switch>
                    </Suspense>
                </Layout>
            </Router>
        );
    }
}

export default App;
